import React, { useState } from 'react';
import { Container, LeadFixText, LeadBackground, LeadBottom, LeadLinks, LeadLink, LeadLinkLogo } from './Lead.styled';
import { LinkI } from '@/interfaces';
import SearchProcedures from '@/components/blocks/SearchProcedures/SearchProcedures';
import Link from 'next/link';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import useTranslation from '@/hooks/useTranslation';
import { sendMetrik } from '@/helpers';

import Mask1 from '@/icons/maskCardLead/1.svg';
import Mask2 from '@/icons/maskCardLead/2.svg';
import Mask3 from '@/icons/maskCardLead/3.svg';
import Head from 'next/head';

const SvgElements: { [key in string]: JSX.Element } = {
    0: <Mask1 />,
    1: <Mask2 />,
    2: <Mask3 />
};

const mapStateToProps = ({ preloader, pageTransition }: RootState) => ({
    preloader,
    pageTransition
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export type LeadType = {
    title: string;
    description: string;
    fixText: LinkI;
    background: {
        fhd: string;
        tablet: string;
        mobile: string;
        fhdWEBP: string;
        tabletWEBP: string;
        mobileWEBP: string;
    };
    companies: {
        name: string;
        href: string;
        defaultImage: string;
        hoverImage: string;
    }[];
};

const masks = {
    fhd: '/images/index/lead/mask-fhd-min.png'
};

const Lead: React.FC<PropsFromRedux & LeadType> = ({ title, description, fixText, companies }) => {
    const { t } = useTranslation('index');
    const [lead] = useState<LeadType>(() => t('lead', { returnObjects: true }));

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    as="image"
                    href={lead.background.fhd}
                    imageSrcSet={`${lead.background?.fhdWEBP} 10240w, ${lead.background.fhd} 10240w, ${lead.background?.tabletWEBP} 1024w, ${lead.background.tablet} 1024w, ${lead.background?.mobileWEBP} 768w, ${lead.background.mobile} 768w, ${lead.background?.mobileWEBP} 335w, ${lead.background.mobile} 335w`}
                />

                <link rel="preload" as="image" href={masks.fhd} />
            </Head>
            <Container className={'_prepare'}>
                {title && <h2>{title}</h2>}

                {description && <p dangerouslySetInnerHTML={{ __html: description }} />}

                {fixText && (
                    <LeadFixText href={fixText.href} target="_blank" rel="noreferrer">
                        {fixText.text}
                    </LeadFixText>
                )}

                <LeadBackground
                    layout="fill"
                    objectFit="cover"
                    src={lead.background.mobile}
                    sources={[
                        {
                            src: lead.background.fhdWEBP,
                            breakpoint: 1024
                        },
                        {
                            src: lead.background.fhd,
                            breakpoint: 1024
                        },
                        {
                            src: lead.background.tabletWEBP,
                            breakpoint: 768
                        },
                        {
                            src: lead.background.tablet,
                            breakpoint: 768
                        },
                        {
                            src: lead.background.mobileWEBP,
                            breakpoint: 375
                        },
                        {
                            src: lead.background.mobileWEBP,
                            breakpoint: 1
                        }
                    ]}
                    bgImgXXl={masks.fhd}
                />

                <LeadBottom>
                    <SearchProcedures />

                    {companies && (
                        <LeadLinks>
                            {companies.map((company, index) => (
                                <Link href={company.href} passHref prefetch={false} key={index}>
                                    <LeadLink
                                        onClick={() => sendMetrik('search-click-section-teaser', 'search', { 'teaser-name': company.name })}
                                    >
                                        {company.defaultImage && (
                                            <LeadLinkLogo src={company.defaultImage} layout="fill" objectFit="contain" />
                                        )}
                                        {company.hoverImage && <LeadLinkLogo src={company.hoverImage} layout="fill" objectFit="contain" />}

                                        {SvgElements[index]}
                                    </LeadLink>
                                </Link>
                            ))}
                        </LeadLinks>
                    )}
                </LeadBottom>
            </Container>
        </>
    );
};

export default connector(Lead);
