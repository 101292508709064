import styled from 'styled-components';
import { color, mediaBreakpointUp, offset, sideOffsets, vw } from '@/style/mixins';
import { Colors } from '@/style/colors';
import { BaseOffsetsUI } from '@/interfaces';

export const Container = styled.div<
    BaseOffsetsUI & {
        hasSideOffsets?: boolean;
        sideOffsetProperty: 'padding' | 'margin';
        verticalOffsetProperty: 'padding' | 'margin';
        bgColor?: Colors;
        dFlex?: boolean;
        fhdSides?: boolean;
    }
>`
    position: relative;
    ${({ hasSideOffsets, sideOffsetProperty }) => hasSideOffsets && sideOffsets(sideOffsetProperty)}
    ${({ topOffset, verticalOffsetProperty }) => topOffset && offset(topOffset, `${verticalOffsetProperty}-top`)}
    ${({ bottomOffset, verticalOffsetProperty }) => bottomOffset && offset(bottomOffset, `${verticalOffsetProperty}-bottom`)}
    ${({ bgColor }) => bgColor && `background-color: ${color(bgColor)};`}
    ${({ dFlex }) => dFlex && `display: flex;`}

    ${({ fhdSides }) =>
        fhdSides &&
        `
            ${mediaBreakpointUp('fhd')} {
                padding-right: ${vw(80)};
                padding-left: ${vw(80)};
            }
    `}
`;
