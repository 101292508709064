import styled, { css } from 'styled-components';
import { cols, color, font, vw, mediaBreakpointUp, smoothValue, mediaBreakpointDown, allBreakpointValue } from '@/style/mixins';

export const Container = styled.div`
    position: relative;
    flex-shrink: 0;
    width: 100%;

    ${mediaBreakpointUp('xl')} {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: ${vw(735, 'xl')};
        padding: 20px;
        color: ${color('black')};
        background-color: ${color('white')};
        border-radius: 8px;
    }

    ${mediaBreakpointUp('xxl')} {
        width: ${vw(784, 'xxl')};
        padding: 22px;
    }

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(995)};
        padding: ${vw(30)};
    }

    p {
        margin-bottom: 9px;
        ${font('text2-b')};

        ${mediaBreakpointUp('xl')} {
            margin-bottom: 6px;
        }

        ${mediaBreakpointUp('fhd')} {
            margin-bottom: ${vw(8)};
        }
    }
`;

export const SearchProceduresInput = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    ${mediaBreakpointUp('xl')} {
        margin-bottom: 5px;
    }

    ${mediaBreakpointUp('xxl')} {
        margin-bottom: 7px;
    }

    ${mediaBreakpointUp('fhd')} {
        margin-bottom: ${vw(11)};
    }

    input {
        width: calc(100% - (44px + 8px));
        padding: 14px 12px 15px;
        ${font('button')};
        line-height: 1.1;
        outline: none;
        border: none;
        border-radius: 8px;

        ${mediaBreakpointUp('md')} {
            padding: 15px 16px 14px;
        }

        ${mediaBreakpointUp('xl')} {
            width: 100%;
            padding: 9px ${cols(1.5)} 19px 0;
            border-radius: 0;
            border-bottom: 1px solid ${color('black', 0.1)};
            transition: border-color 0.3s ease-in-out;

            &:hover,
            &:focus {
                border-color: ${color('black', 0.25)};
            }
        }

        ${mediaBreakpointUp('fhd')} {
            padding: ${vw(13)} ${cols(1.5)} ${vw(28)} 0;
        }

        &::-ms-clear {
            display: none;
            height: 0;
            width: 0;
        }
    }

    button {
        flex-shrink: 0;
        width: 44px;
        ${allBreakpointValue('height', 48, 36, 32, 44, 44)};
        padding: 0;
        ${font('button')};
        background-color: transparent;
        cursor: pointer;
        transform: translateX(calc(-1 * 44px));

        ${mediaBreakpointDown('xl')} {
            display: none;
        }

        ${mediaBreakpointUp('xl')} {
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
            color: ${color('white')};
            background-color: ${color('black')};
            border-radius: 8px;
            transform: none;
            transition: color 0.3s ease-in-out;

            &:disabled {
                color: ${color('white', 0.2)};
                cursor: default;
            }

            &:hover:not([disabled]) {
                color: ${color('white', 0.5)};
            }
        }

        ${mediaBreakpointUp('xxl')} {
            padding-left: 22px;
            padding-right: 22px;
        }

        ${mediaBreakpointUp('fhd')} {
            padding-left: ${vw(30)};
            padding-right: ${vw(30)};
        }
    }
`;

export const SearchProceduresButtons = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;

    ${mediaBreakpointDown('xl')} {
        height: 44px;
        right: 50px;
    }
`;

export const SearchProceduresClear = styled.div`
    position: relative;
    width: 14px;
    height: 14px;
    margin-right: 12px;
    opacity: 0.3;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        margin-right: 20px;
    }

    ${mediaBreakpointUp('xl')} {
        width: 18px;
        height: 18px;
    }

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(18)};
        height: ${vw(18)};
    }

    &:hover {
        opacity: 1;
    }

    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: ${color('black')};
    }

    &::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;

export const SearchProceduresTags = styled.div`
    display: flex;
    width: 100%;
    margin-top: 16px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    ${mediaBreakpointUp('md')} {
        margin-top: 24px;
    }

    ${mediaBreakpointUp('xl')} {
        flex-wrap: wrap;
        width: ${smoothValue(515, 580, 1280, 1440, 'w')};
        margin-top: auto;
    }

    ${mediaBreakpointUp('xxl')} {
        width: ${smoothValue(580, 640, 1440, 1920, 'w')};
    }

    ${mediaBreakpointUp('fhd')} {
        width: ${cols(8)};
    }
`;

export const SearchProceduresTag = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    ${font('text2-l')};
    color: ${color('white', 0.9)};
    border: 1px solid ${color('white', 0.5)};
    border-radius: 30px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    white-space: nowrap;

    &:not(:last-child) {
        margin-right: 4px;
    }

    ${mediaBreakpointUp('md')} {
        height: 38px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    ${mediaBreakpointUp('xl')} {
        height: 33px;
        margin-top: 4px;
        color: ${({ active }) => (active ? `${color('white')}` : `${color('black')}`)};
        background-color: ${({ active }) => (active ? `${color('blue')}` : `${color('gray')}`)};
        border: none;

        &:hover {
            ${({ active }) =>
                !active &&
                css`
                    color: ${color('black', 0.5)};
                `}
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    ${mediaBreakpointUp('xxl')} {
        height: 34px;
        margin-top: 5px;
    }

    ${mediaBreakpointUp('fhd')} {
        height: ${vw(38)};
        margin-top: ${vw(5)};
    }
`;

export const SearchProceduresLink = styled.a`
    position: absolute;
    top: 28px;
    right: 0;
    width: 44px;
    height: 44px;
    ${font('text2-l')};
    background-color: ${color('black', 0.2)};
    border-radius: 8px;

    ${mediaBreakpointDown('xl')} {
        font-size: 0;
    }

    ${mediaBreakpointUp('xl')} {
        position: relative;
        top: auto;
        display: flex;
        align-items: center;
        width: auto;
        height: auto;
        margin-top: 9px;
        margin-left: auto;
        background-color: transparent;
        border-radius: 0;
    }

    ${mediaBreakpointUp('xxl')} {
        margin-top: 12px;
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }

    svg {
        display: none;

        &:last-child {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            transform: translate(-50%, -50%);
        }

        ${mediaBreakpointUp('xl')} {
            display: block;
            width: 8px;
            height: 12px;
            margin-left: 10px;
            stroke: currentColor;
            transition: transform 0.3s ease-in-out;

            &:last-child {
                display: none;
            }
        }
    }
`;
