import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import React from 'react';
import Layout from '@/components/common/Layout/Layout';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getProcedures, getServicesBlock, getContentPrivateApi, getYTTag, getPageData, getPagePreview } from '@/lib/api';
import useTranslation from '@/hooks/useTranslation';
import { BasePageProps, INewsCard } from '@/interfaces';
import Lead, { LeadType } from '@/components/blocks/Lead/Lead';
import { IndicatorsSectionProps } from '@/components/sections/IndicatorsSection/IndicatorsSection';
import { SuppliersSectionI } from '@/components/sections/SuppliersSection/SuppliersSection';
import { CustomersSectionProps } from '@/components/sections/CustomersSection/CustomersSection';
import { getDesiredDate, getEventDate, sendMetrik } from '@/helpers';
import { getBannersData, getSlugBanners, updateDataBannerSlider, updateDataMainBanners } from '@/helpers/bannerHelpers';
import { Card1Props } from '@/components/blocks/Card1/Card1';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Spacer from '@/components/common/Spacer/Spacer';
import { getOurCustomersFullData } from '@/helpers/data/getOurCustomersFullData';
import { getAxiosPromiseData } from '@/helpers/data/getAxiosPromiseData';
import { useHideComponents } from '@/hooks/useHideComponents';
import { getLoggingHeaders } from '@/helpers/api/getLoggingHeaders';

const BannerSlider = dynamic(() => import('@/components/blocks/BannerSlider/BannerSlider'));
const EducationSection = dynamic(() => import('@/components/sections/EducationSection/EducationSection'));
const VideoSliderSection = dynamic(() => import('@/components/sections/VideoSliderSection/VideoSliderSection'));
const CustomersSection = dynamic(() => import('@/components/sections/CustomersSection/CustomersSection'));
const SuppliersSection = dynamic(() => import('@/components/sections/SuppliersSection/SuppliersSection'));
const CustomersSliderSection = dynamic(() => import('@/components/sections/CustomersSliderSection/CustomersSliderSection'));
const ServicesSection = dynamic(() => import('@/components/sections/ServicesSection/ServicesSection'));
const Banner = dynamic(() => import('@/components/common/Banner/Banner'));
const NewsSection = dynamic(() => import('@/components/sections/NewsSection/NewsSection'));
const IndicatorsSection = dynamic(() => import('@/components/sections/IndicatorsSection/IndicatorsSection'));

const bannersCodes = ['main_top', 'main_middle'];

interface PageMainData {
    lead?: LeadType;
    customersSection?: CustomersSectionProps;
    indicators?: IndicatorsSectionProps;
    suppliersSection?: SuppliersSectionI;
}
interface IndexProps extends BasePageProps {
    videoSliderItems: Card1Props[];
}

const actualProceduresStatuses = [
    'Приём заявок',
    'Приём заявок 1 этапа',
    'На рассмотрении поставщика',
    'Подача заявок',
    'Приём оферт',
    'Приём предложений'
];

const yandexVerification = '75501044106e84fd';

const Index: React.FC<IndexProps & InferGetServerSidePropsType<typeof getServerSideProps>> = ({
    meta,
    lead,
    customersSection,
    indicators,
    suppliersSection,
    videoSliderItems,
    servicesBlock,
    educationsEvents,
    cursesCards,
    newsPressCards,
    newsMediaCards,
    ourCustomers,
    bannersTop,
    bannerMiddle,
    actualProceduresTotal
}) => {
    const { t } = useTranslation('index');
    const { locale } = useRouter();

    const isShownComponents = useHideComponents();

    return (
        <Layout meta={meta}>
            <Head>
                <meta name="yandex-verification" content={yandexVerification} key="yandex-verification" />
            </Head>

            {lead && <Lead {...lead} />}

            {!!bannersTop?.length && (
                <Spacer fhdSides>
                    <BannerSlider
                        content={{ items: bannersTop as any }}
                        bottomOffset="xxxl"
                        metriksSample={{ event: 'main-click-subscribe', category: 'main', action: 'click-section' }}
                    />
                </Spacer>
            )}

            <Spacer fhdSides bgColor="gray2">
                {customersSection && isShownComponents && (
                    <CustomersSection
                        customers={{ ...customersSection, modals: t('customers.modals', { returnObjects: true }) }}
                        metriksSample={{ event: 'main-click-section', category: 'main', action: 'click-section' }}
                        metrika={() => sendMetrik('main-click-sign-in', 'main', { 'click-section': 'customers' })}
                    />
                )}
            </Spacer>

            <Spacer fhdSides bgColor="gray2">
                {indicators && isShownComponents && (
                    <IndicatorsSection indicators={indicators} metrika={() => sendMetrik('main-click-more-about-company', 'main', {})} />
                )}
            </Spacer>

            <Spacer fhdSides>
                {suppliersSection && isShownComponents && (
                    <SuppliersSection
                        suppliers={{ ...suppliersSection, links: t('suppliers.links', { returnObjects: true }) } as any}
                        metrika={() => sendMetrik('main-click-subscribe', 'main', { 'click-section': 'suppliers' })}
                        metriksSample={{ event: 'main-click-advantages', category: 'main', action: 'click-block' }}
                        actualProceduresTotal={actualProceduresTotal}
                        bottomOffset={!!ourCustomers?.length ? 'none' : 'xxxl'}
                    />
                )}
            </Spacer>

            {!!ourCustomers?.length && isShownComponents && (
                <Spacer fhdSides>
                    <CustomersSliderSection
                        metriksSample={{ event: 'list-customers-click', category: 'customers', action: 'click-block' }}
                        content={{
                            ...t('ourCustomers', { returnObjects: true }),
                            items: ourCustomers as any
                        }}
                    />
                </Spacer>
            )}

            {isShownComponents && (
                <Spacer fhdSides bgColor="gray2">
                    <ServicesSection
                        metriksSampleHead={{ event: 'services-block-interaction', category: 'services', action: 'click-block' }}
                        wrapper={t('services', { returnObjects: true })}
                        services={servicesBlock[locale === 'ru' ? 'ru' : 'en']}
                    />
                </Spacer>
            )}

            {!!bannerMiddle?.length && bannerMiddle[0] && isShownComponents && (
                <Spacer fhdSides>
                    <Banner
                        metrika={() => sendMetrik('main-click-pokupka-i-prodaja-imuschestva', 'main', {})}
                        {...bannerMiddle[0]}
                        bottomOffset={!!educationsEvents?.length && !!cursesCards?.length ? 'none' : 'xxxl'}
                    />
                </Spacer>
            )}

            {!!educationsEvents?.length && !!cursesCards?.length && isShownComponents && (
                <Spacer fhdSides>
                    <EducationSection
                        metriksSampleHead={{ event: 'education-block-interaction', category: 'education', action: 'click-block' }}
                        metriksSample={{ event: 'education-block-interaction', category: 'education', action: 'click-education' }}
                        wrapper={t('education', { returnObjects: true })}
                        educationsEventsImages={t('education.educationsEvents.images', { returnObjects: true })}
                        educationsEvents={educationsEvents}
                        cursesCards={cursesCards}
                        cursesCardsHasBanner={false}
                    />
                </Spacer>
            )}

            {isShownComponents && (
                <Spacer fhdSides bgColor="gray2">
                    <NewsSection
                        metriksSampleHead={{ event: 'main-click-news', category: 'main', action: 'click-news' }}
                        metriksSample={{ event: 'main-click-news', category: 'main', action: 'click-news' }}
                        {...t('news', { returnObjects: true })}
                        newsPressCards={newsPressCards}
                        newsMediaCards={newsMediaCards}
                    />
                </Spacer>
            )}

            {!!videoSliderItems.length && isShownComponents && (
                <Spacer fhdSides>
                    <VideoSliderSection
                        metriksSample={{ event: 'main-click-video', category: 'main', action: 'click-video' }}
                        wrapper={t('videoSlider', { returnObjects: true })}
                        content={{ items: videoSliderItems }}
                    />
                </Spacer>
            )}
        </Layout>
    );
};

export const getServerSideProps = async ({ req, query, locale = 'ru' }: GetServerSidePropsContext) => {
    const newsPressCardsPromise = getContentPrivateApi('/news', {
        params: {
            locale: `${locale}`,
            type: 'press_center',
            limit: 6
        },
        headers: getLoggingHeaders(req)
    });
    const newsMediaCardsPromise = getContentPrivateApi('/news', {
        params: {
            locale: `${locale}`,
            type: 'media',
            limit: 6
        },
        headers: getLoggingHeaders(req)
    });
    const cursesCardsPromise = getContentPrivateApi('/events', {
        params: {
            locale: `${locale}`,
            type: 'course',
            limit: 4
        },
        headers: getLoggingHeaders(req)
    });

    const proceduresDataPromise = getProcedures({ headers: getLoggingHeaders(req) });

    const statusParams = actualProceduresStatuses.reduce((acc, item, i) => {
        // @ts-ignore
        acc[`status[${i}]`] = item;
        return acc;
    }, {});
    const actualProceduresTotalPromise = getProcedures({ params: { ...statusParams, sort: 'actual' }, headers: getLoggingHeaders(req) });

    const educationsEventsPromise = getContentPrivateApi('/events', {
        params: {
            locale: `${locale}`,
            type: 'webinar,seminar,online_conference,conference,supplier_day',
            dateStartFrom: getEventDate(getDesiredDate()),
            limit: 3
        },
        headers: getLoggingHeaders(req)
    });

    let ytTagData = null;
    try {
        const { data } = await getYTTag('main-page', {
            params: { locale },
            headers: getLoggingHeaders(req)
        });

        if (data) ytTagData = data;
    } catch (error) {
        console.error(error);
    }

    const servicesBlock = await getServicesBlock();

    let proceduresData = null;
    let educationsEvents = null;
    let cursesCards = null;
    let newsPressCards: INewsCard[] | null = null;
    let newsMediaCards: INewsCard[] | null = null;
    let actualProceduresTotal = null;

    try {
        const { data } = await educationsEventsPromise;

        if (data?.items) {
            educationsEvents = data.items;
        }
    } catch (e) {}

    // до интеграции здесь были конкретные 4 курса
    try {
        const { data } = await cursesCardsPromise;

        if (data?.items) cursesCards = data.items;
    } catch (e) {}

    const banners = await getBannersData({ codes: bannersCodes, req, locale });
    const ourCustomers = await getOurCustomersFullData({ req, locale, headers: getLoggingHeaders(req) });

    try {
        const { data } = await proceduresDataPromise;

        if (data) proceduresData = data;
    } catch (err) {
        console.log(err);
    }

    try {
        const { data } = await newsPressCardsPromise;

        if (data.items) {
            data.items.forEach((item: INewsCard) => {
                item.tags = [{ title: locale === 'ru' ? 'Новости' : 'News' }];
            });

            newsPressCards = data.items;
        }
    } catch (err) {
        console.log(err);
    }

    try {
        const { data } = await newsMediaCardsPromise;

        if (data?.items) {
            data.items.forEach((item: INewsCard) => {
                item.tags = [{ title: locale === 'ru' ? 'СМИ о нас' : 'Media about us' }];
            });

            newsMediaCards = data.items;
        }
    } catch (err) {
        console.log(err);
    }

    try {
        const { data } = await actualProceduresTotalPromise;
        if (data?.total) actualProceduresTotal = data.total;
    } catch (err) {
        console.log(err);
    }

    let videoSliderItems: Card1Props[] = [];
    if (ytTagData?.playlists?.length) {
        videoSliderItems = ytTagData.playlists.map(({ id, title, picture }) => ({
            title: title,
            image: picture,
            bgColor: 'blue',
            link: {
                text: '',
                href: `https://www.youtube.com/playlist?list=${id}`,
                isForeign: true
            },
            titleBg: true,
            target: '_blank'
        }));
    }

    let pageData = null;
    const hash = query?.hash;

    if (hash) {
        pageData = await getAxiosPromiseData(
            getPagePreview<PageMainData>(hash, {
                params: { locale },
                headers: getLoggingHeaders(req)
            })
        );

        if (!pageData) {
            pageData = await getAxiosPromiseData(
                getPageData<PageMainData>('main', {
                    params: { locale },
                    headers: getLoggingHeaders(req)
                })
            );
        }
    } else {
        pageData = await getAxiosPromiseData(
            getPageData<PageMainData>('main', {
                params: { locale },
                headers: getLoggingHeaders(req)
            })
        );
    }

    return {
        props: {
            meta: {
                title:
                    locale === 'ru'
                        ? 'ЭТП ТЭК-Торг - электронная торговая площадка для участия в закупках, тендерах и аукционах'
                        : 'ETP TEK-Torg - electronic trading platform for participation in procurement, tenders and auctions',
                description:
                    locale === 'ru'
                        ? 'Федеральная электронная торговая площадка для проведения государственных, коммерческих и малых закупок, имущественных торгов. Нам доверяют крупнейшие госкорпорации России!'
                        : 'Federal electronic trading platform for government and commercial procurement, small-volume purchases, sale of property. We are trusted by the largest state corporations of Russia!',
                keywords: ''
            },
            lead: pageData?.lead ?? null,
            customersSection: pageData?.customersSection ?? null,
            indicators: pageData?.indicators ?? null,
            suppliersSection: pageData?.suppliersSection ?? null,
            videoSliderItems,
            servicesBlock,
            educationsEvents: educationsEvents ?? null,
            cursesCards: cursesCards ?? null,
            newsPressCards: newsPressCards ?? null,
            newsMediaCards: newsMediaCards ?? null,
            ourCustomers: ourCustomers ?? null,
            bannersTop: updateDataBannerSlider(getSlugBanners(banners, bannersCodes[0]), locale),
            bannerMiddle: updateDataMainBanners(getSlugBanners(banners, bannersCodes[1])),
            actualProceduresTotal,
            ...(await serverSideTranslations(locale, ['common', 'index'])),
            initialReduxState: {
                searchProcedures: {
                    status: 'idle',
                    error: null,
                    data: [],
                    total: proceduresData?.total || null
                }
            }
        }
    };
};

export default Index;
