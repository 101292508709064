import React from 'react';
import { Container } from './Spacer.styled';
import { Colors } from '@/style/colors';
import { BaseOffsetsUI } from '@/interfaces';

interface SpacerProps extends BaseOffsetsUI {
    tag?: React.ElementType;
    hasSideOffsets?: boolean;
    sideOffsetProperty?: 'padding' | 'margin';
    verticalOffsetProperty?: 'padding' | 'margin';
    fhdSides?: boolean;
    dFlex?: boolean;
    bgColor?: Colors;
    id?: string;
}

const Spacer: React.FC<SpacerProps> = ({
    tag = 'div',
    hasSideOffsets,
    topOffset,
    bottomOffset,
    sideOffsetProperty = 'padding',
    verticalOffsetProperty = 'padding',
    fhdSides,
    bgColor,
    dFlex,
    children,
    id
}) => {
    return (
        <Container
            as={tag}
            hasSideOffsets={hasSideOffsets}
            topOffset={topOffset}
            bottomOffset={bottomOffset}
            sideOffsetProperty={sideOffsetProperty}
            verticalOffsetProperty={verticalOffsetProperty}
            fhdSides={fhdSides}
            bgColor={bgColor}
            dFlex={dFlex}
            id={id}
        >
            {children}
        </Container>
    );
};

export default Spacer;
