import React from 'react';
import { BaseUI } from '@/interfaces';
import { Container } from '@/components/common/Picture/Picture.styled';
import useIsIE11 from '@/hooks/useIsIE11';
import { ImageProps } from 'next/dist/client/image';

export type PictureType = BaseUI & {
    src: string;
    sources?: {
        src: string;
        breakpoint: number;
    }[];

    alt?: string;
    objectFit?: ImageProps['objectFit'];
    objectPosition?: ImageProps['objectPosition'];
};

const defaultOption = {
    alt: 'alt',
    objectFit: 'cover',
    objectPosition: '50% 50%'
};

const Picture: React.FC<PictureType> = (props) => {
    const isIE11 = useIsIE11();
    const options = {
        ...defaultOption,
        ...props
    };

    return (
        <Container className={options.className} sources={options.sources} src={props.src} isIE11={isIE11} as={isIE11 ? 'div' : 'picture'}>
            {'sources' in options &&
                options.sources &&
                !!options.sources.length &&
                options.sources.map(({ src, breakpoint }) => (
                    <source
                        srcSet={src}
                        media={`(min-width: ${Number(breakpoint) === 1280 ? 1024 : breakpoint}px)`}
                        key={`${Math.random()}-${src}`}
                    />
                ))}
            <img
                srcSet={props.src}
                alt={props.alt ?? ''}
                width={1920}
                height={1080}
                // @ts-ignore
                style={
                    isIE11
                        ? {
                              fontFamily: `'object-fit: ${options.objectFit}; object-position: ${options.objectPosition};'`,
                              width: '100%',
                              height: '100%',
                              objectFit: options.objectFit,
                              objectPosition: options.objectPosition
                          }
                        : {}
                }
            />
        </Container>
    );
};

export default Picture;
