import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RootState } from '@/redux/rootReducer';
import { connect, ConnectedProps } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import useIsMobile from '@/hooks/useIsMobile';
import useTranslation from '@/hooks/useTranslation';
import {
    Container,
    SearchProceduresInput,
    SearchProceduresClear,
    SearchProceduresTags,
    SearchProceduresTag,
    SearchProceduresLink,
    SearchProceduresButtons
} from './SearchProcedures.styled';
import SearchMobile from '@/icons/search-mobile.svg';
import Arrow from '@/icons/arrow.svg';
import { LinkI } from '@/interfaces';
import { resetSearchProcedures } from '@/redux/searchProcedures/searchProceduresSlice';
import { sendMetrik } from '@/helpers';

const mapStateToProps = ({ searchProcedures }: RootState) => ({
    searchProcedures
});
const mapDispatchToProps = {
    resetSearchProcedures
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type SearchProceduresType = {
    text1: string;
    text2: string;
    text3: string;
    notFideMessage: string[];
    input: {
        placeholder: string;
    };
    btn: string;
    link: LinkI;
    tags: {
        text: string;
        value: string;
    }[];
};

export type FilterData = {
    name: string;
    sectionsCodes?: string[];
};

const SearchProcedures: React.FC<PropsFromRedux> = ({ searchProcedures, resetSearchProcedures }) => {
    const { t } = useTranslation('index');
    const [textContent] = useState<SearchProceduresType>(() => t('searchProcedures', { returnObjects: true }));
    const [inputValue, setInputValue] = useState('');
    const [allActive, setAllActive] = useState(true);
    const [filters, setFilters] = useState<FilterData | null>();
    const [sectionsCodesList, setSectionsCodesList] = useState<string[]>([]);
    const router = useRouter();
    const isMobile = useIsMobile();

    const goToSearchPage = () => {
        const object: any = filters;
        let queries: any = {};

        Object.keys(object).forEach((key) => {
            if (key === 'sectionsCodes' && object[key].length > 0) {
                queries[`${key}[]`] = object[key].join(';');
            } else {
                queries[key] = object[key];
            }
        });

        router.push({
            pathname: '/procedures',
            query: {
                ...queries
            }
        });
    };

    const onClickTag = (value: string) => {
        if (sectionsCodesList.indexOf(value) !== -1) {
            setSectionsCodesList((prevState) =>
                prevState.filter(function (f) {
                    return f !== value;
                })
            );
        } else {
            sendMetrik('search-click-section', 'search', { 'click section': value });
            setSectionsCodesList((prevState) => [...prevState, value]);
        }
    };

    const onClickFirstTag = () => {
        if (allActive) return;
        setAllActive(true);
        setSectionsCodesList([]);
    };

    const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);

        if (e.target.value) {
            setFilters({ name: e.target.value, sectionsCodes: sectionsCodesList });
        } else {
            setFilters((prevState) => {
                const copy = { ...prevState };
                delete copy['name'];
                return copy;
            });
        }
    };

    const clearHandler = () => {
        setInputValue('');
        setFilters((prevState) => {
            const copy = { ...prevState };
            delete copy['name'];
            return copy;
        });
        resetSearchProcedures();
    };

    useEffect(() => {
        if (!!sectionsCodesList.length) {
            setAllActive(false);
            setFilters((prevState) => ({ ...prevState, sectionsCodes: sectionsCodesList }));
        } else {
            setAllActive(true);
            setFilters((prevState) => {
                const copy = { ...prevState };
                delete copy['sectionsCodes'];
                return copy;
            });
        }
    }, [sectionsCodesList]);

    return (
        <Container>
            <p>
                {textContent.text1} {searchProcedures.total} {textContent.text2}
            </p>

            <SearchProceduresInput>
                <DebounceInput
                    minLength={3}
                    value={inputValue}
                    onChange={onChangeInputHandler}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            goToSearchPage();
                        }
                    }}
                    placeholder={textContent.input.placeholder}
                />

                <SearchProceduresButtons>
                    {!!inputValue.length && <SearchProceduresClear onClick={clearHandler} />}
                    {!isMobile && (
                        <button
                            onClick={() => {
                                sendMetrik('search-click-search', 'search', {});
                                goToSearchPage();
                            }}
                        >
                            {textContent.btn}
                        </button>
                    )}
                </SearchProceduresButtons>
            </SearchProceduresInput>
            {textContent.tags && !isMobile && (
                <SearchProceduresTags>
                    <SearchProceduresTag active={allActive} onClick={onClickFirstTag}>
                        {textContent.text3}
                    </SearchProceduresTag>
                    {textContent.tags.map((tag, index) => (
                        <SearchProceduresTag
                            key={index}
                            onClick={() => onClickTag(tag.value)}
                            active={sectionsCodesList.includes(tag.value)}
                        >
                            {tag.text}
                        </SearchProceduresTag>
                    ))}
                </SearchProceduresTags>
            )}

            {textContent.link && (
                <Link href={textContent.link.href} prefetch={false} passHref>
                    <SearchProceduresLink onClick={() => sendMetrik('search-click-advanced-search', 'search', {})}>
                        {textContent.link.text}
                        <Arrow />
                        <SearchMobile />
                    </SearchProceduresLink>
                </Link>
            )}
        </Container>
    );
};

export default connector(SearchProcedures);
