import styled, { keyframes } from 'styled-components';
import {
    font,
    color,
    vw,
    vh,
    mediaBreakpointUp,
    mediaBreakpointDown,
    smoothValue,
    hover,
    offset,
    allBreakpointValue,
    sideOffsets
} from '@/style/mixins';
import Image from '@/components/common/Image/Image';
import Picture from '@/components/common/Picture/Picture';

export const LeadBottom = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    z-index: 1;

    ${mediaBreakpointUp('xl')} {
        flex-wrap: nowrap;
    }

    ${mediaBreakpointUp('fhd')} {
        justify-content: space-between;
    }
`;

export const LeadFixText = styled.a`
    align-self: flex-start;
    display: block;
    ${allBreakpointValue('top', 343, 257, 228)};
    ${font('text1-l')};
    margin-bottom: 16px;

    ${mediaBreakpointUp('md')} {
        margin-bottom: 26px;
    }

    ${mediaBreakpointUp('xl')} {
        position: absolute;
        right: 0;
        padding: 16px 20px;
        margin-bottom: 0;
        writing-mode: tb-rl;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        color: ${color('black')};
        background-color: ${color('white')};
        transform: scale(-1);
    }

    ${mediaBreakpointUp('fhd')} {
        padding: 20px 25px;
    }

    ${hover(`
        color: ${color('black', 0.5)}
    `)};
`;

const changeOpacity = keyframes`
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    ${sideOffsets()};
    padding-top: ${vh(130, 812)};
    ${allBreakpointValue('padding-bottom', 80, 60, 54, 80, 60)};
    color: ${color('white')};
    background-color: ${color('blue')};

    ${mediaBreakpointDown('md')} {
        @media (max-height: 400px) {
            padding-top: 130px;
        }
    }

    ${mediaBreakpointUp('md')} {
        padding-top: ${vh(180, 1024)};
    }

    ${mediaBreakpointUp('xl')} {
        min-height: 100vh;
        padding-top: ${smoothValue(145, 165, 1280, 1440, 'w')};

        ._is-ie & {
            height: 100vh;
        }
    }

    ${mediaBreakpointUp('fhd')} {
        padding-top: ${vw(220)};
        padding-right: ${vw(160)};
        padding-left: ${vw(160)};
    }

    & > h2 {
        ${offset('l', 'margin-bottom')};
        ${font('h1')};
        opacity: 1;
        transition: opacity 0.7s ease-in-out;
        z-index: 1;

        animation: ${changeOpacity} 0.7s ease-in-out forwards;

        ${mediaBreakpointUp('md')} {
            width: ${vw(960)};
        }
    }

    & > p {
        ${offset('s', 'margin-bottom')};
        ${font('text1-l')};
        opacity: 1;
        transition: opacity 0.7s ease-in-out;
        z-index: 1;

        animation: ${changeOpacity} 0.7s ease-in-out forwards;

        ${mediaBreakpointUp('md')} {
            width: ${vw(960)};
        }

        ${mediaBreakpointUp('xl')} {
            width: ${vw(640)};
        }
    }

    ${LeadBottom} {
        opacity: 1;
        transition: opacity 0.7s ease-in-out;

        animation: ${changeOpacity} 0.7s ease-in-out forwards;
    }

    ${LeadFixText} {
        z-index: 1;

        ${mediaBreakpointUp('xl')} {
            opacity: 1;
            transition: opacity 0.7s ease-in-out, color 0.4s ease-in-out;

            animation: ${changeOpacity} 0.7s ease-in-out forwards;
        }
    }

    &._prepare {
        ${LeadBottom},
        & > h1,
        & > p {
            opacity: 0;
            transition: none;
        }

        ${LeadFixText} {
            ${mediaBreakpointUp('xl')} {
                opacity: 0;
                transition: none;
            }
        }
    }
`;

export const LeadBackground = styled(({ bgImg, bgImgXXl, ...props }) => <Picture {...props} />)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${mediaBreakpointUp('xl')} {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${({ bgImgXXl }) => bgImgXXl && ` top / cover no-repeat url(${bgImgXXl})`};
        }
    }
`;

export const LeadLinkLogo = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: none;
    transition: opacity 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        transform: scale(1.1);
    }

    ${mediaBreakpointUp('xl')} {
        transform: none;
    }

    img {
        opacity: 1 !important;
    }

    &:last-of-type {
        opacity: 0;
    }
`;

export const LeadLinks = styled.div`
    display: flex;
    width: 100%;

    ${mediaBreakpointDown('xl')} {
        margin-top: 40px;
    }

    ${mediaBreakpointDown('md')} {
        margin-top: 32px;
    }

    ${mediaBreakpointUp('xxl')} {
        width: auto;
    }
`;

export const LeadLink = styled.a`
    position: relative;
    width: 100%;
    ${allBreakpointValue('width', 186, 163)};
    height: 120px;
    background-color: ${color('white')};
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
    overflow: hidden;

    &:not(:first-child) {
        margin-left: 8px;
    }

    ${mediaBreakpointUp('xl')} {
        height: 100%;

        &:first-child {
            margin-left: 8px;
        }
    }

    ${mediaBreakpointUp('fhd')} {
        &:not(:first-child) {
            margin-left: ${vw(13)};
        }
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.15;
        display: none;
        ${mediaBreakpointUp('xl')} {
            display: block;
        }

        ${mediaBreakpointUp('fhd', 50)} {
            width: 110%;
        }
    }

    ${hover(`
        background-color: ${color('blue')};

        ${LeadLinkLogo} {
            &:first-of-type {
                opacity: 0;
            }
            &:last-of-type {
                opacity: 1;
            }
        }
    `)};
`;
