import { useEffect, useState } from 'react';

export const useHideComponents = () => {
    const [state, setState] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            setState(true);
            globalThis.document.removeEventListener('scroll', onScroll);
        };
        globalThis.document.addEventListener('scroll', onScroll);
    }, []);

    return state;
};
